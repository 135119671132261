import React from "react"
import Presentation from "./presentation"

export default function Main() {
  return (
    <>
      <Presentation></Presentation>
    </>
  )
}
